:root {
	--accent-color: #0f544d;
	--primary-color: #f3b508;
	--primary-contrast-color: #ffffff;

	--primary-idle-button-color: #f3b508;
	--primary-button-border-color: rgba(255, 255, 255, 0.5);

	--secondary-idle-button-color: #f3b508;
	--secondary-hover-button-color: #fffcf5;
	--secondary-pressed-button-color: #fdf6e1;

	--tertiary-idle-button-color: rgba(255, 255, 255, 0.6);
	--tertiary-hover-button-color: rgba(255, 255, 255, 0.5);
	--tertiary-pressed-button-color: rgba(255, 255, 255, 0.4);
	--tertiary-contrast-color: #000000;

	--font-caption-color: linear-gradient(180deg, #1c7159 -3.12%, #05504b 100%);

	--font-size-md: 2rem;
}
