:root {
	--accent-color: #0f544d;
	--primary-color: #f3b508;
	--primary-contract-color: #ffffff;

	--primary-idle-button-color: #f3b508;

	--secondary-idle-button-color: #f3b508;
	--secondary-hover-button-color: #fffcf5;
	--secondary-pressed-button-color: #fdf6e1;

	--font-caption-color: linear-gradient(180deg, #1c7159 -3.12%, #05504b 100%);

	--font-size-md: 2rem;
}
