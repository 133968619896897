button.primary,
button.secondary,
button.tertiary {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 12px;
	height: 56px;
	border-radius: 8px;
	cursor: pointer;
	outline: none;
	padding: 0;
}

button.primary {
	background-color: var(--primary-idle-button-color);
	color: var(--primary-contrast-color);
	border: 2px solid var(--primary-button-border-color);
}

button.secondary {
	color: var(--primary-color);
	background-color: var(--primary-contrast-color);
	border: 1px solid var(--secondary-idle-button-color);
}

button.secondary:hover {
	background-color: var(--secondary-hover-button-color);
}

button.secondary:active {
	background-color: var(--secondary-pressed-button-color);
}

button.tertiary {
	background-color: var(--tertiary-idle-button-color);
	border: none;
	color: var(--tertiary-contrast-color);
}

button.tertiary:hover {
	background-color: var(--tertiary-hover-button-color);
}

button.tertiary:active {
	background-color: var(--tertiary-pressed-button-color);
}
