* {
	box-sizing: border-box;
	line-height: 24px;
	font-size: 24px;
	font-weight: 400;
	font-family: "Be Vietnam Pro", sans-serif, system-ui;
}

html,
body {
	margin: 0;
	min-height: 100dvh;
}

p {
	margin: 0;
}
