.overlay {
	--background-blur: blur(10px);

	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	width: 100vw;
	height: 100dvh;
	backdrop-filter: var(--background-blur);
	-webkit-backdrop-filter: var(--background-blur);
}
